import React, { useState, useEffect } from "react";
import axios from "axios";
import { mainUri, mainImagesUri } from "../../Utils/Uri";
import { connect, useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import { updateUserInfo } from "../../redux/actions/useraction";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const MobileVerification = (props) => {
  const [value, setValue] = useState("");
  const [otp, setOtp] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [isNonEditable, setIsNonEditable] = useState(false);
  const [time, setTime] = useState(0);
  const [isError, setIsError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (props.isHaveMobile) {
      setIsNonEditable(true);
      setValue(props.user.user.mobile);
    }
  }, []);

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time]);

  // handleMobileInput
  const handleMobileInput = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setValue(event.target.value);
    }
  };
  // send otp to user's mobile no
  const sendOtp = () => {
    if (value.length < 10) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter a valid mobile number",
        timer: 1500,
      });
    } else {
      setIsLoading(true);
      axios
        .post(
          `${mainUri}/users/send-sms-otp`,
          {
            user: props.user.user.id,
            mobile: value,
          },
          {
            headers: {
              "auth-token": props.user.token,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            setTime(59);
            setOtpScreen(true);
            Swal.fire({
              position: "center",
              icon: "success",
              title: result.message,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "warning",
              title: result.message,
              timer: 1500,
            });
          }
          setIsLoading(false);
          // console.log("mobile otp status", result);
        })
        .catch((err) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: err.response.data.message,
            timer: 1500,
          });
          console.log("mobile otp status", err.response);
          setIsLoading(false);
        });
    }
  };

  // resend OTP
  const resendOtp = () => {
    setIsLoadingNew(true);
    axios
      .post(
        `${mainUri}/users/send-sms-otp`,
        {
          user: props.user.user.id,
          mobile: value,
        },
        {
          headers: {
            "auth-token": props.user.token,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setTime(59);
          setOtpScreen(true);
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: result.message,
            timer: 1500,
          });
        }
        setIsLoadingNew(false);
        // console.log("mobile otp status", result);
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: err.response.data.message,
          timer: 1500,
        });
        console.log("mobile otp status", err.response);
        setIsLoadingNew(false);
      });
  };

  // Verify OTP
  const verifyOtp = () => {
    if (otp === "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter OTP",
        timer: 1500,
      });
    } else {
      setIsLoading(true);
      axios
        .put(
          `${mainUri}/users/sms-otp-verify`,
          {
            user: props.user.user.id,
            otp: otp,
          },
          {
            headers: {
              "auth-token": props.user.token,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            getUserInfoData();
            Swal.fire({
              position: "center",
              icon: "success",
              title: result.message,
              timer: 1500,
            });
          }
          setIsLoading(false);
          console.log("mobile otp match >> ", result);
        })
        .catch((err) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: err.message,
            timer: 1500,
          });
          setIsLoading(false);
        });
    }
  };

  // get User information
  const getUserInfoData = () => {
    axios
      .get(`${mainUri}/users/user/${props.user.user.id}`, {
        headers: {
          "auth-token": props.user.token,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          props.updateUserInformation(result.data);
        }
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            user: null,
            token: null,
            isLoggedIn: false,
          });
          localStorage.removeItem("prepFront");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/");
        }
      });
  };
  // handleEditMobile
  const handleEditMobile = () => {
    setValue("");
    setIsNonEditable(false);
  };
  return (
    <div className="mobile_verification_wrapper">
      <div className="container" style={{ height: "100%" }}>
        <div className="mobile_verification_body">
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              <div className="card">
                {!otpScreen ? (
                  <div className="card-body">
                    <div className="text-center form-group mt-4"></div>
                    <h4 className="text-center">
                      Please verify your mobile number
                    </h4>
                    <div className="mobile_number_wrapper">
                      {isNonEditable && (
                        <span className="editButton" onClick={handleEditMobile}>
                          <i className="fa fa-pencil"></i>
                        </span>
                      )}

                      <input
                        type="tel"
                        value={value}
                        onChange={handleMobileInput}
                        placeholder="Enter your mobile number"
                        className="form-control"
                        disabled={isNonEditable ? true : false}
                        minLength={10}
                        required
                      />
                      <div className="text-center mt-4">
                        {isLoading ? (
                          <button className="btn btn-primary">
                            <i className="fa fa-spinner fa-spin" /> Sending...
                          </button>
                        ) : (
                          <button className="btn btn-primary" onClick={sendOtp}>
                            Send OTP
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body otp_wrapper">
                    <h4 className="text-center">Please verify OTP</h4>
                    <div className="mobile_number_wrapper">
                      <OtpInput
                        value={otp}
                        onChange={(code) => setOtp(code)}
                        numInputs={6}
                        separator={<span>-</span>}
                      />
                      <div className="text-center mt-4">
                        {isLoading ? (
                          <button className="btn btn-primary">
                            <i className="fa fa-spinner fa-spin" /> Verifying...
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={verifyOtp}
                          >
                            Verify
                          </button>
                        )}

                        <div className="text-center mt-2">
                          {time > 0 ? "Resend in" : "Not received yet?"}
                          {isLoadingNew ? (
                            <span className="resend_otp">
                              <i className="fa fa-spinner fa-spin"></i>
                            </span>
                          ) : (
                            <>
                              {time > 0 ? (
                                <span className="resend_otp">0:{time} sec</span>
                              ) : (
                                <span
                                  className="resend_otp"
                                  onClick={resendOtp}
                                >
                                  Resend OTP
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateUserInformation: (data) => dispatch(updateUserInfo(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileVerification);
