const INITIAL_STATE = {
  upi: null,
  type: null,
};

const paymentOptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PAYMENT_TYPE_UPDATE":
      return {
        ...state,
        upi: action.payload.upi,
        type: action.payload.type,
      };
    default:
      return state;
  }
};

export default paymentOptionReducer;
