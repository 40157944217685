import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { mainUri } from "../../Utils/Uri";
import Swal from "sweetalert2";
import Loading from "../Loading";
import MobileVerification from "../Verfication/MobileVerification";
import UserHeader from "./components/UserHeader";
import UserNavBar from "./components/UserNavBar";
import EmailVerification from "../Verfication/EmailVerification";
import CourseCardHorizontal from "./components/CourseCardHorizontal";
import { useHistory } from "react-router-dom";
import moment from "moment";

const MyBundles = (props) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  // get my courses
  const getMyBundles = () => {
    axios
      .get(`${mainUri}/bundle/user-details/${props.user.user.id}`, {
        headers: {
          "auth-token": props.user.token,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setList(result.data.reverse());
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "warning",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            user: null,
            token: null,
            isLoggedIn: false,
          });
          localStorage.removeItem("prepFront");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/");
        }
      });
  };
  useEffect(() => {
    getMyBundles();
  }, []);
  return (
    <>
      {props.user.user.mobile === null && !props.user.user.emailVerified && (
        <EmailVerification />
      )}
      {/* {props.user.user.mobile === null && props.user.user.emailVerified && (
        <MobileVerification />
      )}
      {props.user.user.mobile !== null &&
        props.user.user.isActive === false && (
          <MobileVerification isHaveMobile />
        )} */}
      <div className="container userPage_wrapper">
        {isLoading && <Loading />}
        <h4 className="account_page_title">Account</h4>
        <UserHeader />
        <div className="row">
          <div className="col-md-12">
            <div className="user_wrapper_container">
              <UserNavBar />
              <div className="card card-default">
                <div className="card-body">
                  {list.length > 0 ? (
                    <div className="row">
                      {list.map((item, index) => {
                        const isExpired = !moment(item.expiryDate).isAfter(
                          moment(Date.now())
                        );
                        return (
                          <div className={"col-md-12"} key={index}>
                            <CourseCardHorizontal
                              data={item}
                              link={`/bundle/${item.slug}`}
                              bundle
                              isExpired={isExpired}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="alert alert-dark">
                      You are not enrolled in any bundle
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(MyBundles);
