import React from "react";
import { connect } from "react-redux";
import LogoImg from "../assets/logo-small.png";

const Loading = (props) => {
  return (
    <div className={"main_loader_container"}>
      <div className="loadingio-spinner-spinner-bql8n33rdvh">
        <div className="ldio-hpfsdhpdhyw">
          <img src={LogoImg} alt="Logo" className="loader_logo" />
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <span className={"loading_title"}>Loading...</span>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    website: state.website,
  };
};
export default connect(mapStateToProps)(Loading);
