import {
  Switch,
  Route,
  BrowserRouter as Router,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import "./styles/App.scss";
import { useEffect, lazy, Suspense } from "react";
import {
  userLoginAction,
  updateWishlistAction,
  userLogoutAction,
} from "./redux/actions/useraction";
import { useDispatch } from "react-redux";

// Import components & pages
import Header from "./layout/Header";
import Footer from "./layout/Footer";
// import Home from "./pages/Home/Home";
// import CourseList from "./pages/course/List";
// import CourseDetails from "./pages/course/CourseDetails";
// import BlogList from "./pages/blogs/List";
// import BlogDetails from "./pages/blogs/BlogDetails";
import CategoryPage from "./pages/category/CategoryPage";
// import AboutUs from "./pages/about/AboutUs";
// import TermsPrivacyPolicy from "./pages/TermsPrivacyPolicy";
// import ContactUs from "./pages/contact/ContactUs";
import ErrorPage from "./pages/ErrorPage";
// import Login from "./pages/auth/login";
// import Register from "./pages/auth/register";
import { connect } from "react-redux";
// import Dashboard from "./pages/user/Dashboard";
// import EditProfile from "./pages/user/EditProfile";
// import ChangePassword from "./pages/user/ChangePassword";
// import MyCourses from "./pages/user/MyCourses";
// import MyInvoices from "./pages/user/MyInvoices";
// import Wishlist from "./pages/user/Wishlist";
// import VideoPage from "./pages/VideoPage/VideoPage";
// import ThankYou from "./pages/ThankYou/ThankYou";
// for Quiz
import QuizList from "./pages/Quiz/QuizList";
// import QuizView from "./pages/Quiz/QuizView";

// import Solution from "./pages/Quiz/Solution";
// import SolutionDetails from "./pages/Quiz/SolutionDetails";
// import QuizReport from "./pages/Quiz/report/QuizReport";
// end Quiz
// for Mock
// import MockList from "./pages/Mock/MockList";
// import MockView from "./pages/Mock/MockView";
// import Instruction from "./pages/Mock/Instruction";
// import Instruction2 from "./pages/Mock/Instruction2";
// import MockSolution from "./pages/Mock/MockSolution";
// import MockSolutionDetails from "./pages/Mock/MockSolutionDetails";
// import MockReport from "./pages/Mock/report/MockReport";
import AllToppers from "./pages/toppers/Toppers";
import CartPage from "./pages/cart/CartPage";
// import Checkout from "./pages/checkout/Checkout";
// end Mock

// for bundle
// import BundleList from "./pages/bundle/BundleList";
// end Bundle
import { websiteDataUpdateAction } from "./redux/actions/websiteAction";
import { addToCartAction } from "./redux/actions/cartActions";
import axios from "axios";
import { mainUri } from "./Utils/Uri";
// import FooterNew from "./layout/FooterNew";
import ForgotPassword from "./pages/auth/forgotPassword";
import ForgotPasswordOtp from "./pages/auth/forgotPasswordOtp";
import UpdatePassword from "./pages/auth/updatePassword";
import Swal from "sweetalert2";
import devtools from "devtools-detect";
// import BundleDetails from "./pages/bundle/BundleDetails";
import MyBundles from "./pages/user/MyBundles";
import HeaderNew from "./layout/HeaderNew";
// import AllCourses from "./pages/allCourses/index";

// import DailyReads from "./pages/DailyReads/index";

// Practice forum
// import PracticeForum from "./pages/Practice-forum/index";
// import PracticeForumDetails from "./pages/Practice-forum/slug";
// import PracticeForumCategory from "./pages/Practice-forum/category";

// Testimonials
// import Testimonials from "./pages/Testimonials/index";

// Results
// import Results from "./pages/Results/index";

// College predictor
// import Predictor from "./pages/Predictors/index";
import { paymentOptionUpdateAction } from "./redux/actions/paymentOptionActions";

const FooterNew = lazy(() => import("./layout/FooterNew"));

// import lazy componets
const Home = lazy(() => import("./pages/Home/Home"));
const TermsPrivacyPolicy = lazy(() => import("./pages/TermsPrivacyPolicy"));
const AboutUs = lazy(() => import("./pages/about/AboutUs"));
const CourseList = lazy(() => import("./pages/course/List"));
const CourseDetails = lazy(() => import("./pages/course/CourseDetails"));
const BlogList = lazy(() => import("./pages/blogs/List"));
const BlogDetails = lazy(() => import("./pages/blogs/BlogDetails"));
const ContactUs = lazy(() => import("./pages/contact/ContactUs"));
const VideoPage = lazy(() => import("./pages/VideoPage/VideoPage"));
const Login = lazy(() => import("./pages/auth/login"));
const Register = lazy(() => import("./pages/auth/register"));
const Dashboard = lazy(() => import("./pages/user/Dashboard"));
const EditProfile = lazy(() => import("./pages/user/EditProfile"));
const ChangePassword = lazy(() => import("./pages/user/ChangePassword"));
const MyCourses = lazy(() => import("./pages/user/MyCourses"));
const MyInvoices = lazy(() => import("./pages/user/MyInvoices"));
const Wishlist = lazy(() => import("./pages/user/Wishlist"));
const ThankYou = lazy(() => import("./pages/ThankYou/ThankYou"));
const QuizView = lazy(() => import("./pages/Quiz/QuizView"));
const Solution = lazy(() => import("./pages/Quiz/Solution"));
const SolutionDetails = lazy(() => import("./pages/Quiz/SolutionDetails"));
const QuizReport = lazy(() => import("./pages/Quiz/report/QuizReport"));
const MockList = lazy(() => import("./pages/Mock/MockList"));
const Instruction = lazy(() => import("./pages/Mock/Instruction"));
const Instruction2 = lazy(() => import("./pages/Mock/Instruction2"));
const MockSolution = lazy(() => import("./pages/Mock/MockSolution"));
const MockSolutionDetails = lazy(() => import("./pages/Mock/MockSolutionDetails"));
const MockReport = lazy(() => import("./pages/Mock/report/MockReport"));
const Checkout = lazy(() => import("./pages/checkout/Checkout"));
const BundleList = lazy(() => import("./pages/bundle/BundleList"));
const BundleDetails = lazy(() => import("./pages/bundle/BundleDetails"));
const AllCourses = lazy(() => import("./pages/allCourses/index"));
const DailyReads = lazy(() => import("./pages/DailyReads/index"));
const PracticeForum = lazy(() => import("./pages/Practice-forum/index"));
const PracticeForumDetails = lazy(() => import("./pages/Practice-forum/slug"));
const PracticeForumCategory = lazy(() => import("./pages/Practice-forum/category"));
const Testimonials = lazy(() => import("./pages/Testimonials/index"));
const Results = lazy(() => import("./pages/Results/index"));
const Predictor = lazy(() => import("./pages/Predictors/index"));



// install("G-2EEWJHLV2Z");

const App = (props) => {
  const isLoggedIn = props.user.isLoggedIn;
  const userToken = props.user.token;
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const getUserDetails = async () => {
    const user = await localStorage.getItem("prepFront");
    if (user) {
      const userUpdate = JSON.parse(user);
      let id = await userUpdate.data.id;
      let token = await userUpdate.token;
      await getUserData(id, token);
      // props.userLoggedIn(JSON.parse(user));
    } else {
      // console.log("user not found");
    }
  };

  // useEffect(() => {
  //   if (devtools.isOpen) {
  //     if (window.innerWidth > 1280) {
  //       alert("inspect element dhoka hai , padh le beta mauka hai");
  //       window.location.reload();
  //     }
  //   }

  //   window.addEventListener("devtoolschange", (event) => {
  //     if (event.detail.isOpen) {
  //       if (window.innerWidth > 1280) {
  //         alert("inspect element dhoka hai , padh le beta mauka hai");
  //         window.location.reload();
  //       }
  //     }
  //     console.log("Is DevTools open:", event.detail.isOpen);
  //   });
  // }, [devtools.isOpen]);

  // getUserData
  const getUserData = async (id, token) => {
    await axios
      .get(`${mainUri}/users/user/${id}`, {
        headers: {
          "auth-token": token,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          const data = {
            data: result.data,
            token: token,
          };
          props.userLoggedIn(data);
        } else {
          localStorage.removeItem("prepFront");
          props.userLogout();
          history.push("/");
        }
        // console.log("user detaisl result, ", result);
      })
      .catch((err) => {
        if (err.response.data === "Invalid token") {
          dispatch({
            type: "USER_LOGOUT",
            user: null,
            token: null,
            isLoggedIn: false,
          });
          localStorage.removeItem("prepFront");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your token has been expired!, Please login again",
            showConfirmButton: false,
            timer: 1500,
          });
          history.push("/");
        }
      });
  };

  // get Website data
  const getWebsiteData = () => {
    axios.get(`${mainUri}/frontend/general`).then((res) => {
      const result = res.data;
      if (result.success) {
        if (result.data.length > 0) {
          props.updateWebData(result.data[0]);
        }
      }
    });
  };
  // get wishlist
  const getWishlist = () => {
    axios
      .get(`${mainUri}/wishlist/${props.user.user.id}`, {
        headers: {
          "auth-token": userToken,
        },
      })
      .then((res) => {
        const list = res.data;
        if (list.success) {
          props.wishlistUpdate(list.data);
        }
        // console.log("wishlist data >> ", list);
      });
  };

  // get paymentData
  const paymentData = () => {
    axios.get(`${mainUri}/payment-option/getinfo`, {}).then((res) => {
      const list = res.data;
      // console.log("paymentOptions>>>>", list.data);
      if (list.success) {
        props.paymentOptionUpdate(list.data);
      }
      // console.log("wishlist data >> ", list);
    });
  };

  useEffect(() => {
    getUserDetails();
    getWebsiteData();
    paymentData();
  }, []);

  useEffect(() => {
    if (props.user.user !== null) {
      getWishlist();
      // getUserCart();
    } else {
      return;
    }
  }, [props.user.user]);
  return (
    <div className="main_container">
      <HeaderNew />
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <Route exact path="/" component={Home} />
          <Route path="/terms-and-conditions" component={TermsPrivacyPolicy} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/cat-iim-mba-college-predictor" component={Predictor} />

          {!isLoggedIn && <Route path="/login" component={Login} />}
          {!isLoggedIn && <Route path="/register" component={Register} />}
          {!isLoggedIn && (
            <Route path="/forgot-password" component={ForgotPassword} />
          )}
          {!isLoggedIn && (
            <Route path="/forgot-password-otp" component={ForgotPasswordOtp} />
          )}
          {!isLoggedIn && (
            <Route path="/update-password" component={UpdatePassword} />
          )}
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/results" component={Results} />
          {/* <Route path="/alltoppers" component={AllToppers} /> */}
          {/* <Route exact path="/quiz" component={QuizList} /> */}
          <Route exact path="/bundles" component={BundleList} />
          <Route exact path="/bundle/:slug" component={BundleDetails} />
          {/* <Route path="/cart" component={CartPage} /> */}
          <Route path="/checkout" component={Checkout} />
          {isLoggedIn && (
            <Route path="/quiz/report/:id" component={QuizReport} />
          )}
          {isLoggedIn && (
            <Route path="/quiz/solution/:id" component={Solution} />
          )}
          {isLoggedIn && (
            <Route
              path="/quiz/solution-details/:id"
              component={SolutionDetails}
            />
          )}

          {isLoggedIn && <Route path="/quiz/:id" component={QuizView} />}
          <Route exact path="/mock" component={MockList} />
          {isLoggedIn && (
            <Route path="/mock/report/:id" component={MockReport} />
          )}
          {isLoggedIn && (
            <Route path="/mock/solution/:id" component={MockSolution} />
          )}
          {isLoggedIn && (
            <Route
              path="/mock/solution-details/:id"
              component={MockSolutionDetails}
            />
          )}
          {isLoggedIn && (
            <Route path="/mock/instructions/:id" component={Instruction} />
          )}
          {isLoggedIn && (
            <Route path="/mock/instructions2/:id" component={Instruction2} />
          )}
          {/* {isLoggedIn && <Route path="/mock/:id" component={MockView} />} */}
          <Route exact path="/blogs" component={BlogList} />
          <Route path="/blogs/:slug" component={BlogDetails} />
          {isLoggedIn && <Route path="/thank-you/:id" component={ThankYou} />}
          {isLoggedIn && <Route path="/course/:id" component={VideoPage} />}
          {isLoggedIn && (
            <Route path="/user" component={Dashboard}>
              <Route path="/user/dashboard" component={Dashboard} />
              <Route path="/user/edit-profile" component={EditProfile} />
              <Route path="/user/change-password" component={ChangePassword} />
              <Route path="/user/my-courses" component={MyCourses} />
              <Route path="/user/my-bundles" component={MyBundles} />
              <Route path="/user/my-invoices" component={MyInvoices} />
              <Route path="/user/wishlist" component={Wishlist} />
              <Route path="/all-courses" component={CourseList} />
            </Route>
          )}
          <Route exact path="/daily-reads" component={DailyReads} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route exact path="/practice-forum" component={PracticeForum} />
          <Route
            exact
            path="/practice-forum/:slug"
            component={PracticeForumCategory}
          />
          <Route
            exact
            path="/practice-forum/:slug/:slug"
            component={PracticeForumDetails}
          />

          <Route exact path="/courses" component={AllCourses} />
          <Route path="/:slug" component={CourseDetails} />
          <Route exact path="/404" component={ErrorPage} />
        </Suspense>
      </Switch>
      {/* <Footer /> */}
      <Suspense>
        <FooterNew />
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  userLoggedIn: (data) => dispatch(userLoginAction(data)),
  updateWebData: (data) => dispatch(websiteDataUpdateAction(data)),
  wishlistUpdate: (data) => dispatch(updateWishlistAction(data)),
  paymentOptionUpdate: (data) => dispatch(paymentOptionUpdateAction(data)),
  userLogout: () => dispatch(userLogoutAction()),
  cartAdd: (data) => dispatch(addToCartAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
