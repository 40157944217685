import React from "react";
import { NavLink, useHistory } from "react-router-dom";

function DesktopMenu({ courses, bundles }) {
  const history = useHistory();
  // handleCourseLink
  const handleCourseLink = (value, type) => {
    if (type !== "bundle") {
      history.push(`/${value}`);
    } else {
      history.push(`/bundle/${value}`);
    }
  };
  return (
    <div className="desktop_menu">
      <ul className="nav_links">
        <li className="nav_link_item">
          <NavLink
            to="/about-us"
            className="navbar_link"
            activeClassName="active"
          >
            About Us
          </NavLink>
        </li>
        <li className="nav_link_item">
          <div to="/courses" className="navbar_link" activeClassName="active">
            <span className={`cursor-pointer`}>
              <NavLink to="/courses">Courses </NavLink>
              {courses.length > 0 && (
                <i className="fa fa-angle-down arrow_down_icon"></i>
              )}
            </span>
            {courses.length > 0 && (
              <div className="navigation_dd_wrapper">
                {bundles.length > 0 && (
                  <>
                    <div className="navigation_dd_parent">
                      <div className="parent_row_name cursor-pointer d-flex justify-content-between">
                        <span>Full Courses</span>
                        <i className="fa fa-angle-right"></i>
                      </div>
                      <div className="navigation_dd_child">
                        {bundles.map((bundle) => {
                          if (bundle.isPublished) {
                            if (
                              bundle.title !==
                              "CAT 2022 - Batch 1 Full Course : Basic to Advanced with Personalised mentorship"
                            ) {
                              return (
                                <div
                                  className="dd_course cursor-pointer"
                                  key={bundle._id}
                                  onClick={() =>
                                    handleCourseLink(bundle.slug, "bundle")
                                  }
                                >
                                  <div className="dd_course_image">
                                    <img
                                      src={bundle.image}
                                      alt={bundle.title}
                                      width={50}
                                    />
                                  </div>
                                  <div className="dd_course_title">
                                    {bundle.title}
                                  </div>
                                </div>
                              );
                            }
                          }
                        })}
                      </div>
                    </div>
                  </>
                )}
                {courses.map((item) => {
                  const enrolledCourses =
                    item.courses.length > 0
                      ? item.courses.filter((c) => c.enrollment === true)
                      : [];
                  return (
                    <>
                      {enrolledCourses.length > 0 &&
                        [...item.courses].sort(
                          (a, b) =>
                            Number(b.isPublished) - Number(a.isPublished)
                        )[0].isPublished === true && (
                          <div className="navigation_dd_parent" key={item._id}>
                            <div className="parent_row_name cursor-pointer d-flex justify-content-between">
                              <span>{item.title}</span>
                              <i className="fa fa-angle-right"></i>
                            </div>
                            <div className="navigation_dd_child">
                              {item.courses.map((course) => {
                                if (course.isPublished && course.enrollment) {
                                  return (
                                    <div
                                      className="dd_course cursor-pointer"
                                      key={course._id}
                                      onClick={() =>
                                        handleCourseLink(course.course_url)
                                      }
                                    >
                                      <div className="dd_course_image">
                                        <img
                                          src={course.course_image}
                                          alt={course.title}
                                          width={50}
                                        />
                                      </div>
                                      <div className="dd_course_title">
                                        {course.title}
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        )}
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </li>
        <li className="nav_link_item">
          <span className={`cursor-pointer`}>
            <span className="navbar_link">Free Practice </span>
            <i className="fa fa-angle-down arrow_down_icon"></i>
          </span>
          {/* <NavLink to="/blogs" className="navbar_link" activeClassName="active">
            Blogs
          </NavLink> */}
          <div className="navigation_dd_w">
            <div className="inner">
              <div className="nav_it">
                <NavLink
                  to="/blogs"
                  className="navbar_link"
                  activeClassName="active"
                >
                  Blogs
                </NavLink>
              </div>
              <div className="nav_it">
                <NavLink
                  to="/daily-reads"
                  className="navbar_link"
                  activeClassName="active"
                >
                  Daily Reads
                </NavLink>
              </div>
              <div className="nav_it">
                <NavLink
                  to="/practice-forum"
                  className="navbar_link"
                  activeClassName="active"
                >
                  Practice Forum
                </NavLink>
              </div>
            </div>
          </div>
        </li>
        <li className="nav_link_item">
          <NavLink
            to="/contact-us"
            className="navbar_link"
            activeClassName="active"
          >
            Contact us
          </NavLink>
        </li>
        {/* <li className="nav_link_item">
          
          <NavLink
            to="/testimonials"
            className="navbar_link"
            activeClassName="active"
          >
            Testimonials
          </NavLink>
        </li> */}
        <li className="nav_link_item">
          <span className={`cursor-pointer`}>
            <span className="navbar_link">Results </span>
            <i className="fa fa-angle-down arrow_down_icon"></i>
          </span>
          {/* <NavLink to="/blogs" className="navbar_link" activeClassName="active">
            Blogs
          </NavLink> */}
          <div className="navigation_dd_w">
            <div className="inner">
              <div className="nav_it">
                <NavLink
                  to="/results"
                  className="navbar_link"
                  activeClassName="active"
                >
                  Results
                </NavLink>
              </div>
              <div className="nav_it">
                <NavLink
                  to="/testimonials"
                  className="navbar_link"
                  activeClassName="active"
                >
                  Testimonials
                </NavLink>
              </div>
            </div>
          </div>
        </li>
        <li className="nav_link_item button-shape">
          <NavLink
            to="/cat-iim-mba-college-predictor"
            className="navbar_link"
            activeClassName="active"
          >
            <span class="bell fa fa-bell"></span> B-School Predictor
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default DesktopMenu;
