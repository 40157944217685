import { combineReducers } from "redux";
import userReducer from "./userReducer";
import websiteReducer from "./websiteReducer";
import cartReducer from "./cartReducer";
import courseDetailsReducer from "./courseDetailsReducer";
import bundleReducer from "./bundleReducer";
import paymentOptionReducer from "./paymentOptionReducer";

const rootReducer = combineReducers({
  user: userReducer,
  website: websiteReducer,
  cart: cartReducer,
  courseDetails: courseDetailsReducer,
  bundleDetails: bundleReducer,
  paymentOption: paymentOptionReducer,
});

export default rootReducer;
