const INITIAL_STATE = {
  details: null,
  price: 0,
};

const bundleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "BUNDLE_DETAILS_UPDATED":
      return {
        ...state,
        details: action.payload,
        price: parseFloat(action.payload.price),
      };
    case "BUNDLE_PRICE_UPDATED":
      return {
        ...state,
        price: parseFloat(action.payload),
      };

    default:
      return state;
  }
};

export default bundleReducer;
