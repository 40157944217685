import React from "react";
import { useLocation, Link } from "react-router-dom";

const UserNavBar = () => {
  const { pathname } = useLocation();
  // console.log("all avalable params", pathname);
  return (
    <div className="user_nav_bar">
      <Link
        to="/user/dashboard"
        className={`navlink ${pathname === "/user/dashboard" ? "active" : ""}`}
      >
        General
      </Link>
      <Link
        to="/user/my-courses"
        className={`navlink ${pathname === "/user/my-courses" ? "active" : ""}`}
      >
        My Courses
      </Link>
      <Link
        to="/user/my-bundles"
        className={`navlink ${pathname === "/user/my-bundles" ? "active" : ""}`}
      >
        My Combo Packs
      </Link>
      <Link
        to="/user/wishlist"
        className={`navlink ${pathname === "/user/wishlist" ? "active" : ""}`}
      >
        My Wishlist
      </Link>
      <Link
        to="/user/change-password"
        className={`navlink ${
          pathname === "/user/change-password" ? "active" : ""
        }`}
      >
        Change Password
      </Link>
    </div>
  );
};

export default UserNavBar;
