const INITIAL_STATE = {
  details: null,
  price: 0,
};

const courseDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "COURSE_DETAILS_UPDATED":
      return {
        ...state,
        details: action.payload,
        price: action.newPrice,
      };
    case "COURSE_PRICE_UPDATED":
      return {
        ...state,
        price: action.payload,
      };

    default:
      return state;
  }
};

export default courseDetailsReducer;
