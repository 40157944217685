const INITIAL_STATE = {
  user: null,
  isLoggedIn: false,
  token: null,
  wishlist: [],
  myCourses: [],
  myQuiz: [],
  myMock: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USER_LOGGEDIN":
      return {
        ...state,
        user: action.payload,
        token: action.token,
        myQuiz: action.payload.quiz,
        myMock: action.payload.mock,
        myCourses: action.payload.courses,
        isLoggedIn: true,
      };
    case "USER_COURSE_UPDATE":
      return {
        ...state,
        myCourses: action.payload,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        user: null,
        token: null,
        isLoggedIn: false,
      };

    case "USER_WISHLIST_UPDATE":
      return {
        ...state,
        wishlist: action.payload,
      };
    case "USER_INFO_UPDATE":
      return {
        ...state,
        user: action.payload,
        myQuiz: action.payload.quiz,
      };
    case "USER_QUIZ_UPDATE":
      return {
        ...state,
        myQuiz: action.payload,
      };
    case "USER_MOCK_UPDATE":
      return {
        ...state,
        myMock: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
