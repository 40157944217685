import React from "react";
import ErrorImg from "../assets/images/page-error.png";

const ErrorPage = () => {
  return (
    <div className="error_page_container">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 offset-sm-2">
            <div className="d-flex justify-content-center">
              <img src={ErrorImg} alt="Page Not Found" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
