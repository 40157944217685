import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import UserIcon from "../../../assets/icons/user.png";

const UserHeader = (props) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  // handle logout
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("prepFront");
        dispatch({ type: "USER_LOGOUT", payload: "LOGOUT" });
        history.push("/");
      }
    });
  };
  return (
    <div className="header_area_user justify-content-between mobile-not-flex mobile-center">
      <div className="d-flex align-items-center mobile-not-flex mobile-center">
        <div className="user_image_wrapper">
          <img
            src={
              user.profile_picture !== null ? user.profile_picture : UserIcon
            }
            className={"user_image"}
            alt="user profile imge"
          />
        </div>
        <div className="user_content_details">
          <h5 className="user_name">{user.name}</h5>
          <p className="user_email">{user.email}</p>
        </div>
      </div>
      <div className="signout_button">
        {/* <button className="btn btn-primary" onClick={() => handleLogout()}>
          Signout
        </button> */}
      </div>
    </div>
  );
};

export default UserHeader;
