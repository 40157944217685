import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { mainUri } from "../../Utils/Uri";
import axios from "axios";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";
import { userLoginAction } from "../../redux/actions/useraction";
import qs from "qs";

const ForgotPasswordOtp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const email = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).email;
  const history = useHistory();
  //   console.log("asadsd", email);

  const verifyOtp = () => {
    setIsLoading(true);
    axios
      .post(`${mainUri}/users/verify-otp`, {
        email: email.toLowerCase(),
        otp,
      })
      .then((res) => {
        const details = res.data;
        if (details.success) {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: details.message,
            showConfirmButton: false,
            timer: 2500,
          });
          history.push(`/update-password/?email=${email}&otp=${otp}`);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: details.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  return (
    <div className="login-container">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="login_wrapper mobile_verification_body">
              <div className="login_header">
                <h2 className="login-title">Forgot Password?</h2>
              </div>
              <div className="login_body">
                <div className="mobile_number_wrapper">
                  <OtpInput
                    value={otp}
                    onChange={(code) => setOtp(code)}
                    numInputs={6}
                    separator={<span>-</span>}
                    className="otp_input"
                    containerStyle={{ justifyContent: "space-between" }}
                  />
                  <div className="text-center mt-4">
                    {isLoading ? (
                      <button className="btn btn-primary">
                        <i className="fa fa-spinner fa-spin" /> Verifying...
                      </button>
                    ) : (
                      <button className="btn btn-primary" onClick={verifyOtp}>
                        Verify
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLoggedIn: (data) => dispatch(userLoginAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordOtp);
