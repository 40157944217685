import React, { useState } from "react";

function CourseCategory({ data, mobileLinkHandle, itemType }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="course_category_wrapper">
      <div
        className="category_header flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{data?.title || "Full Courses"}</span>
        {isOpen ? (
          <i className="ri-arrow-up-s-line"></i>
        ) : (
          <i className="ri-arrow-down-s-line"></i>
        )}
      </div>
      {itemType !== "bundle" ? (
        <>
          {isOpen && (
            <div className="child_course_wrapper bg-white p-4 rounded-md my-3">
              {data?.courses.map((course) => {
                if (course.isPublished && course.enrollment) {
                  return (
                    <div
                      className="child_row"
                      key={course._id}
                      onClick={() => mobileLinkHandle(course.course_url)}
                    >
                      {course.title}
                    </div>
                  );
                }
              })}
            </div>
          )}
        </>
      ) : (
        <>
          {isOpen && (
            <div className="child_course_wrapper bg-white p-4 rounded-md my-3">
              {data.map((course) => {
                if (course.isPublished) {
                  if (
                    course.title !==
                    "CAT 2022 - Batch 1 Full Course : Basic to Advanced with Personalised mentorship"
                  ) {
                    return (
                      <div
                        className="child_row"
                        key={course._id}
                        onClick={() => mobileLinkHandle(course.slug, "bundle")}
                      >
                        {course.title}
                      </div>
                    );
                  }
                }
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CourseCategory;
