import React, { useEffect } from "react";
import WOW from "wowjs";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

const CourseCard = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div className="course_card_wrapper">
      <div className="card_image">
        <Link to={props.link}>
          {props.category ? (
            <label className="category_name">
              {props.categoryName.replace("-", " ")}
            </label>
          ) : null}
          <img src={props.img} alt={props.title} />
        </Link>
      </div>
      <div className="card_content">
        <Link to={props.link} className="title">
          {props.title}
        </Link>
        <div className="duration">
          <span style={{ paddingRight: "15px" }}>{props.time}</span>|
          <span style={{ marginLeft: "15px" }}>Online</span>
        </div>
        <div className="batch mb-1">
          <span>
            Expiry Date:{" "}
            <strong>{moment(props.date).format("MMMM Do YYYY")}</strong>
          </span>
        </div>
        {props.description ? (
          <div className="description">
            {ReactHtmlParser(props.description.slice(0, 70))}
          </div>
        ) : null}

        <div className="text-right" style={{ marginTop: "-10px" }}>
          {props.external ? (
            <a href={props.link} className="btn btn-sm btn-link-danger">
              View Course
            </a>
          ) : (
            <Link to={props.link} className="btn btn-sm btn-link-danger">
              View Course
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
