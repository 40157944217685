export const API_BASE_URL = "https://server.myprepzone.com/api";
// export const API_BASE_URL = "http://localhost:5000/api";
export const FRONT_URL = "https://myprepzone.com";

export const getApiUrl = (endpoint) => API_BASE_URL + endpoint;

export const USER_LOGIN = getApiUrl("/users/login");

// TODO: NOTIFICATIONS
export const GET_NOTIFICATION = getApiUrl("/notification/getNotification/");
export const UPDATE_NOTIFICATION = getApiUrl("/notification/updateReadBy/");

// TODO: BLOGS
export const GET_BLOGS = getApiUrl("/blogs");
export const GET_BLOGS_CATEGORY = getApiUrl("/blogs/fetch-by-category/");
export const GET_BLOGS_DETAILS = getApiUrl("/blogs/blog-details/");

// TODO: COURSE
export const GET_COURSES_VIA_CATEGORY = getApiUrl("/coursecat/get-all-details");
export const GET_COURSE_DETAILS = getApiUrl("/course/details/");
export const GET_ALL_COURSES = getApiUrl("/course/front-all");

// TODO: BUNDLES
export const GET_ALL_BUNDLES = getApiUrl("/bundle/list");

// TODO: FRONTEND
export const GET_TERMS = getApiUrl("/frontend/terms");
export const CREATE_CONTACT_ENQUIRY = getApiUrl("/frontend/contact/create");

// TODO: QUIZ
export const GET_QUIZ = getApiUrl("/quiz-new/get-all-active");
export const GET_QUIZ_CATEGORY_DETAILS = getApiUrl(
  "/quiz-new/view-category-slug/"
);
export const GET_QUIZ_CATEGORIES = getApiUrl("/quiz-new/view-categories");
export const GET_QUIZ_DETAILS = getApiUrl("/quiz-new/view-by-slug/");
export const SAVE_ANSWERS = getApiUrl("/quiz-new/save-ans/");

// TODO: DAILY READS
export const GET_DAILY_READS = getApiUrl("/article/all");

// TODO: TESTIMONIALS
export const GET_TESTIMONIALS = getApiUrl("/testimonials");
export const GET_FEATURED_TESTIMONIALS = getApiUrl("/testimonials/featured");

// TODO: ALL RESULT CATEGORY
export const GET_RESULT_CATEGORIES = getApiUrl("/results/all-categories");

// TODO: GET POPUP DETAILS
export const GET_POPUP = getApiUrl("/popup/details");

// TODO: GET PREDICTION
export const GET_PREDICTION = getApiUrl("/predictor/create");

// TODO: EMAIL_VALIDATOR
export const EMAIL_VALIDATOR = getApiUrl("/emails/email-validator");
