import React, { useState, useEffect } from "react";
import axios from "axios";
import { mainUri, mainImagesUri } from "../../Utils/Uri";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import { updateUserInfo } from "../../redux/actions/useraction";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const EmailVerification = (props) => {
  const [value, setValue] = useState("");
  const [otp, setOtp] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNonEditable, setIsNonEditable] = useState(false);
  const [isError, setIsError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  //   console.log("user details >>>>>>>", props.user);

  useEffect(() => {
    if (props.isHaveMobile) {
      setIsNonEditable(true);
      setValue(props.user.user.mobile);
    }
  }, []);

  // handleMobileInput
  const handleMobileInput = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setValue(event.target.value);
    }
  };
  // send otp to user's email
  const sendOtp = () => {
    setIsLoading(true);
    axios
      .post(
        `${mainUri}/users/send-email-otp`,
        {
          user: props.user.user.id,
          email: props.user.user.email,
        },
        {
          headers: {
            "auth-token": props.user.token,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          setOtpScreen(true);
          Swal.fire({
            position: "center",
            icon: "success",
            title: result.message,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: result.message,
            timer: 1500,
          });
        }
        setIsLoading(false);
        // console.log("mobile otp status", result);
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: err.response.data.message,
          timer: 1500,
        });
        console.log("mobile otp status", err.response);
        setIsLoading(false);
      });
  };

  // Verify OTP
  const verifyOtp = () => {
    if (otp === "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please enter OTP",
        timer: 1500,
      });
    } else {
      setIsLoading(true);
      axios
        .put(
          `${mainUri}/users/email-otp-verify`,
          {
            user: props.user.user.id,
            otp: otp,
          },
          {
            headers: {
              "auth-token": props.user.token,
            },
          }
        )
        .then((res) => {
          const result = res.data;
          if (result.success) {
            getUserInfoData();
            Swal.fire({
              position: "center",
              icon: "success",
              title: result.message,
              timer: 1500,
            });
          }
          getUserInfoData();
          setIsLoading(false);
          //   console.log("mobile otp match >> ", result);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.data === "Invalid token") {
            dispatch({
              type: "USER_LOGOUT",
              user: null,
              token: null,
              isLoggedIn: false,
            });
            localStorage.removeItem("prepFront");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your token has been expired!, Please login again",
              showConfirmButton: false,
              timer: 1500,
            });
            history.push("/");
          }
        });
    }
  };

  // get User information
  const getUserInfoData = () => {
    axios
      .get(`${mainUri}/users/user/${props.user.user.id}`, {
        headers: {
          "auth-token": props.user.token,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          props.updateUserInformation(result.data);
        }
      });
  };
  // handleEditMobile
  const handleEditMobile = () => {
    setValue("");
    setIsNonEditable(false);
  };
  return (
    <div className="mobile_verification_wrapper">
      <div className="container" style={{ height: "100%" }}>
        <div className="mobile_verification_body">
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              <div className="card">
                {!otpScreen ? (
                  <div className="card-body">
                    <div className="text-center form-group mt-4">
                      <h4>Verify email to activate your account </h4>
                    </div>
                    <p className="text-center">
                      Please verify your email{" "}
                      <strong>{props.user.user.email}</strong> by clicking on
                      the verification otp sent to your registered email.
                    </p>
                    <p className="text-center">
                      Can't find email that we have sent to you? Please check
                      your spam folder. Please remove us from spam folder to
                      revceive future communication mails.
                    </p>
                    <div className="mobile_number_wrapper">
                      <div className="text-center mt-4">
                        {isLoading ? (
                          <button className="btn btn-primary">
                            <i className="fa fa-spinner fa-spin" /> Sending...
                          </button>
                        ) : (
                          <>
                            <button
                              className="btn btn-primary"
                              onClick={sendOtp}
                            >
                              Send OTP
                            </button>
                            <br />
                            <button className="btn btn-link" onClick={sendOtp}>
                              RESEND EMAIL
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body otp_wrapper">
                    <div className="text-center form-group mt-4"></div>
                    <h6 className="text-center">
                      Please enter verification code(OTP)
                    </h6>
                    <p className="text-center">
                      Please enter the One Time Password (OTP) send to{" "}
                      {props.user.user.email}
                    </p>
                    <div className="mobile_number_wrapper">
                      <OtpInput
                        value={otp}
                        onChange={(code) => setOtp(code)}
                        numInputs={6}
                        separator={<span>-</span>}
                      />
                      <div className="text-center mt-4">
                        {isLoading ? (
                          <button className="btn btn-primary">
                            <i className="fa fa-spinner fa-spin" /> Verifying...
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={verifyOtp}
                          >
                            Verify OTP
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateUserInformation: (data) => dispatch(updateUserInfo(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
