import React from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import GooglePlay from "../assets/images/google_play.png";
import { connect } from "react-redux";

const Footer = (props) => {
  var d = new Date();
  var n = d.getFullYear();

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="about_us">
              <Link to="/">
                <img src={Logo} alt="Logo" className="footer-logo" />
              </Link>
              <label className="company_name">Myprepzone</label>
              <p className="year">&copy; {n}</p>
              {props.website.data !== null && (
                <ul className="social_media">
                  {props.website.data.facebook !== "" && (
                    <li>
                      <a href={props.website.data.facebook} target="_blank">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                  )}

                  {props.website.data.youtube !== "" && (
                    <li>
                      <a href={props.website.data.youtube} target="_blank">
                        <i className="fa fa-youtube-play"></i>
                      </a>
                    </li>
                  )}
                  {props.website.data.instagram !== "" && (
                    <li>
                      <a href={props.website.data.instagram} target="_blank">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  )}
                  {props.website.data.telegram !== "" && (
                    <li>
                      <a href={props.website.data.telegram} target="_blank">
                        <i className="fa fa-telegram"></i>
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer_section">
              <h3 className="title">Quick Links</h3>
              <ul className="footer_list">
                <li>
                  <NavLink to="/about-us" activeClassName="active">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/blogs" activeClassName="active">
                    Blogs
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us" activeClassName="active">
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/terms-privacy-policy" activeClassName="active">
                    Terms Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer_section">
              <h3 className="title">App Link</h3>
              {props.website.data !== null && (
                <a href={props.website.data.androidAppLink} target="_blank">
                  <img
                    src={GooglePlay}
                    alt="google play store"
                    className="playstore_logo"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    website: state.website,
  };
};
export default connect(mapStateToProps)(Footer);
