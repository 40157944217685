const INITIAL_STATE = {
    data: null,
};

const websiteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "WEBSITE_DATA_UPDATE":
            return {
                ...state,
                data: action.payload
            };

        default:
            return state;
    }
};

export default websiteReducer;
