import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { mainUri } from "../../Utils/Uri";
import axios from "axios";
import Swal from "sweetalert2";
import GoogleLogin from "react-google-login";
import { userLoginAction } from "../../redux/actions/useraction";
import qs from "qs";

const ForgotPassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();

  const history = useHistory();

  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${mainUri}/users/forgot-password`, {
        email: data.email.toLowerCase(),
      })
      .then((res) => {
        const details = res.data;
        if (details.success === true) {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: details.message,
            showConfirmButton: false,
            timer: 2500,
          });
          history.push(`/forgot-password-otp/?email=${data.email}`);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: details.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  return (
    <div className="login-container">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="login_wrapper">
              <div className="login_header">
                <h2 className="login-title">Forgot Password?</h2>
              </div>
              <div className="login_body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <p>
                    Please enter your email id to receive forgot password OTP.
                  </p>
                  <div className="form-group">
                    <label className="login_label">Email Address</label>
                    <input
                      id="email"
                      name="email"
                      placeholder="Email"
                      ref={register({
                        required: "Email is required.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Please enter a valid email address.",
                        },
                      })}
                      type="email"
                      className="form-control"
                    />
                    {errors.email && (
                      <span className="isError">{errors.email.message}</span>
                    )}
                  </div>

                  <div className="form-group">
                    {isLoading ? (
                      <button
                        className="btn btn-primary btn-block"
                        style={{ opecity: 0.5 }}
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Send OTP
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-7"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLoggedIn: (data) => dispatch(userLoginAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
