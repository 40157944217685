import React, { useEffect, useState, useRef } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import axios from "axios";
import Logo from "../assets/images/logo.png";
import Grid from "../assets/icons/grid.svg";
import { mainUri, mainImagesUri } from "../Utils/Uri";
import WhatsappImg from "../assets/images/whatsapp.png";
import { connect } from "react-redux";
import { userLogoutAction } from "../../src/redux/actions/useraction";

// import icon images
import UserIcon from "../assets/icons/user.svg";
import CartIcon from "../assets/icons/cart.svg";
import moment from "moment";
const Header = (props) => {
  const history = useHistory();

  const isLoggedIn = props.user.isLoggedIn;

  const inputEl = useRef(null);
  const onButtonClick = () => {
    inputEl.current.click();
  };

  const [stepsEnabled, setStepEnabled] = useState(true);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const openModal = () => {
    setStepEnabled(false);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (isMobileOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [isMobileOpen]);

  const showPosition = (position) => {
    // console.log("position >>", position.coords);
    var crood = position.coords;
    var locationInfo = {
      lat: crood.latitude,
      lon: crood.longitude,
    };
    localStorage.setItem("userLocation", JSON.stringify(locationInfo));
    axios
      .post(`${mainUri}/location/create`, {
        latitude: crood.latitude,
        longtitude: crood.longitude,
      })
      .then((res) => {
        const locationRes = res.data;
        console.log("locations stored !!", locationRes);
      });
  };
  useEffect(() => {
    var locations = localStorage.getItem("userLocation");

    if (locations === undefined || locations === null) {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(showPosition);
      }
    }

    const isMobile = window.innerWidth <= 900;
    if (isMobile) {
      setStepEnabled(false);
    }
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    if (name === "" && phone === "") {
      alert("Please Enter your Name & Mobile No.");
    } else if (phone === "" && name !== "") {
      alert("Please Enter your Mobile No.");
    } else if (name === "" && phone !== "") {
      alert("Please Enter your Name");
    } else {
      axios
        .post(`${mainUri}/enquiry/create`, {
          phone: phone,
          name: name,
        })
        .then((res) => {
          const en = res.data;
          if (en.success) {
            setIsSuccess(true);
          }
          console.log("enquiry sumited ", en);
        });
    }
  };
  const handleSuccess = (e) => {
    setName("");
    setPhone("");
    setIsSuccess(false);
    setIsOpen(false);
  };
  // handle logout
  const handleLogout = () => {
    localStorage.removeItem("prepFront");
    props.userLogout();
    history.push("/");
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const value = e.target.value;
    console.log("search result>>>>", e.target.value);
    // setSearchResult
    if (value) {
      setSearchLoading(true);
      axios
        .get(`${mainUri}/course/search/${e.target.value}`)
        .then((res) => {
          const result = res.data;
          if (result.success) {
            setSearchResult(result.data);
          }
          setSearchLoading(false);
        })
        .catch((err) => {
          console.log("error >> ", err);
          setSearchLoading(false);
        });
    } else if (search === "") {
      setSearchResult([]);
    } else {
      setSearchResult([]);
    }
  };

  // get notifications
  // const getNotifications = () => {
  //   axios
  //     .get(`${mainUri}/notification/getNotification/${props.user.user?.id}`)
  //     .then((res) => {
  //       const result = res.data;
  //       if (result.success) {
  //         // console.log("success >> ", result.data);
  //         setNotifications(result.data.reverse());
  //         if (result.unReadNotifications.length > 0) {
  //           setIsVisible(true);
  //         } else {
  //           setIsVisible(false);
  //         }
  //       }
  //     });
  // };

  // update notification status
  const updateNotificationStatus = (id) => {
    axios
      .put(`${mainUri}/notification/updateReadBy/${id}`, {
        userId: props.user.user?.id,
      })
      .then((res) => {
        const result = res.data;
        if (result.success) {
          // getNotifications();
        }
      });
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     getNotifications();
  //   }
  // }, [isLoggedIn]);

  // handle redirect
  const handleRedirect = (item) => {
    setSearch("");
    setSearchResult([]);
    history.push(`/${item.category.title}/${item.course_url}`);
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setSearchResult([]);
        setSearch("");
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    const concernedElement = document.querySelector(".search-results");

    document.addEventListener("mousedown", (event) => {
      if (concernedElement.contains(event.target)) {
        console.log("Clicked Inside");
      } else {
        setSearchResult([]);
        setSearch("");
      }
    });
  }, []);

  return (
    <>
      <header>
        <div className="container-fluid-custom">
          <div className={"main_header_wrapper"}>
            <div className={"header_logo"}>
              <NavLink className="navbar-brand" to="/">
                <img
                  src={
                    props.website.data !== null ? props.website.data.logo : Logo
                  }
                  alt="3elearning"
                  className="web_logo"
                />
              </NavLink>
              <div className="header_search_wrapper">
                <div className="search-inner">
                  <input
                    type="text"
                    placeholder="Search for courses, mocks and more."
                    className="form-control"
                    value={search}
                    onChange={handleSearch}
                  />
                  <span className="search-icon-wrapper">
                    {searchLoading ? (
                      <i className="fa fa-spinner fa-pulse fa-fw" />
                    ) : (
                      <i className="fa fa-search"></i>
                    )}
                  </span>
                </div>
                {searchResult.length > 0 ? (
                  <div className="search-results">
                    {searchResult.map((item, index) => (
                      <div
                        className="search_result_row"
                        key={item._id}
                        onClick={() => handleRedirect(item)}
                      >
                        <div className="search_row_image">
                          <img src={item.course_image} alt={item.title} />
                        </div>
                        <div className="search_row_content">
                          <h5 className="content_title">{item.title}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="search-results">
                    {!searchLoading && (
                      <>
                        {search !== "" && (
                          <div
                            style={{ padding: "20px 0px", textAlign: "center" }}
                          >
                            no result found for "{search}"
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="navigation_right_part">
              <div className="mobile_search_container">
                <span
                  className="search-icon-wrapper"
                  onClick={() => setIsMobileOpen(true)}
                >
                  {searchLoading ? (
                    <i className="fa fa-spinner fa-pulse fa-fw" />
                  ) : (
                    <i className="fa fa-search"></i>
                  )}
                </span>
              </div>

              <div className={"navbar_right_wrapper"}>
                {isLoggedIn && (
                  <li className="nav-item dfc dropdown mr-2">
                    {isVisible && <span className="notification_dot"></span>}
                    <i className="fa fa-bell-o" />
                    <ul
                      className="dropdown-menu notification_dropdown"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="notification_list">
                        {notifications.length > 0 ? (
                          <>
                            {notifications.map((item) => {
                              const isReaded = item.readby.some(
                                (us) => us.readerId === props.user?.user?.id
                              );
                              return (
                                <Link
                                  to={`${item.type === "course" && "/course"}/${
                                    item.productId
                                  }`}
                                  className={`notification ${
                                    isReaded ? "read" : " "
                                  }`}
                                  key={item._id}
                                  onClick={() =>
                                    !isReaded
                                      ? updateNotificationStatus(item._id)
                                      : console.log("clicked")
                                  }
                                >
                                  <div className="notification_image">
                                    <i className="fa fa-refresh" />
                                  </div>
                                  <div className="notification_content">
                                    <div className="title_and_date">
                                      <strong>Admin</strong> {item.message}
                                    </div>
                                    <span className="notification_date">
                                      {moment(item.createdAt).fromNow()}
                                    </span>
                                  </div>
                                </Link>
                              );
                            })}
                          </>
                        ) : (
                          <small>No notifications</small>
                        )}
                      </div>
                    </ul>
                  </li>
                )}
                {isLoggedIn ? (
                  <li className="nav-item dfc dropdown">
                    <a
                      className="dfc"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="avatar_header">
                        {props.user.user.profile_picture !== null ? (
                          <img src={props.user.user.profile_picture} />
                        ) : (
                          <span>{props.user.user.name.substring(0, 1)}</span>
                        )}
                      </div>
                      <span className="user_name">{props.user.user.name}</span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <Link to="/user/dashboard" className="dropdown-item">
                          <i className="fa fa-user-circle"></i> Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleLogout()}
                        >
                          <i className="fa fa-sign-out"></i> Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <div className={"user_icon_wrapper"}>
                    <img src={UserIcon} alt="Sign in" width="25" />
                    <Link to={"/login"}>Sign In</Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      {isMobileOpen && (
        <div className="mobile_search_wrapper">
          <div
            className="close_layer"
            onClick={() => setIsMobileOpen(false)}
          ></div>
          <div className={isMobileOpen ? "inner height-auto" : "inner"}>
            <div className="list_hanlder">
              <span onClick={() => setIsMobileOpen(false)}></span>
            </div>
            <div className="inner_body">
              <div className="search-inner">
                <input
                  type="text"
                  placeholder="Search for course"
                  className="form-control"
                  value={search}
                  onChange={handleSearch}
                />
                <span className="search-icon-wrapper">
                  {searchLoading ? (
                    <i className="fa fa-spinner fa-pulse fa-fw" />
                  ) : (
                    <i className="fa fa-search"></i>
                  )}
                </span>
              </div>
              {searchResult.length > 0 ? (
                <div className="search-results">
                  {searchResult.map((item, index) => (
                    <div
                      className="search_result_row"
                      key={item._id}
                      onClick={() => {
                        setIsMobileOpen(false);
                        handleRedirect(item);
                      }}
                    >
                      <div className="search_row_image">
                        <img src={item.course_image} alt={item.title} />
                      </div>
                      <div className="search_row_content">
                        <h5 className="content_title">{item.title}</h5>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="search-results">
                  {!searchLoading && (
                    <>
                      {search !== "" && (
                        <div
                          style={{ padding: "20px 0px", textAlign: "center" }}
                        >
                          no result found for "{search}"
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExampleDefault"
            aria-controls="navbarsExampleDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={inputEl}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul className="navbar-nav justify-content-end right_nav_bar">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  exact
                  to="/"
                  activeClassName="active"
                  onClick={onButtonClick}
                >
                  Home <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/about-us"
                  activeClassName="active"
                  onClick={onButtonClick}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/blogs"
                  activeClassName="active"
                  onClick={onButtonClick}
                >
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.3elearning.co.in/"
                  target="_blank"
                  onClick={onButtonClick}
                >
                  Mocks
                </a>
              </li>
              <div className="visible_only_desktop">
                <span className="req_callBack" onClick={() => openModal()}>
                  Request Callback <i className="fa fa-phone bell"></i>
                </span>
              </div>
              <div className="only_mobile_visible">
                <a
                  href="tel:9905050159"
                  className="req_callBack"
                  onClick={onButtonClick}
                >
                  Request Callback <i className="fa fa-phone bell"></i>
                </a>
              </div>
            </ul>
          </div>
        </div>
        {/* Modal */}
      {/* <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h5 className={"modal-title"}>Call Me Back Now</h5>
            <span className="close_modal" onClick={() => closeModal()}>
              <i className="fa fa-times"></i>
            </span>
          </div>
          <div className="modal-body">
            {isSuccess ? (
              <div className="is_success_wrapper">
                <i className="fa fa-check-circle-o isIcons"></i>
                <h2>Success</h2>
                <p>We'll call you shortly!</p>
                <button
                  className="btn btn-primary"
                  style={{ paddingLeft: "30px", paddingRight: "30px" }}
                  onClick={(e) => handleSuccess(e)}
                >
                  Okay
                </button>
              </div>
            ) : (
              <form onSubmit={(e) => submitForm(e)}>
                <p className="text-center" style={{ color: "#555555" }}>
                  Got a question? Give us a call and we'll respond as soon as
                  possible
                </p>
                <div className="small_form">
                  <div className="form-group">
                    <label>Please Enter Your Name</label>
                    <input
                      className="form-control"
                      placeholder="Please Enter Your Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Please Enter Your Mobile No.</label>
                    <input
                      className="form-control"
                      placeholder="+91 XXXX - XXX - XXX"
                      type="number"
                      maxLength="10"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="custom_content_container">
                  <ul className="cc_list">
                    <li>
                      <i className="fa fa-check" aria-hidden="true" />
                      Proper guidance on how to crack competitive exams
                    </li>
                    <li>
                      <i className="fa fa-check" aria-hidden="true"></i>
                      Seek guidance to solve a particular question from us
                    </li>
                  </ul>
                  <p style={{ marginBottom: "15px", fontSize: "13px" }}>
                    <strong>Note:</strong> You'll receive a call between Mon-Sat
                    from 9AM to 9 PM.
                  </p>
                </div>
                <div className="submit_button">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-large"
                  >
                    Request a Callback
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal>
      </nav> */}
      {/* {props.website.data !== null && (
        <div className="whatsapp_wrapper">
          {props.website.data.whatsapp !== "" && (
            <a
              href={`https://wa.me/${props.website.data.whatsapp}`}
              target="_blank"
            >
              <img src={WhatsappImg} alt="whatsapp" />
            </a>
          )}
        </div>
      )} */}
      <div className="whatsapp_wrapper">
        <a
          href={`https://wa.me/+918112269770`}
          className="whatsapp_icon"
          target="_blank"
        >
          <span className="wp_title">Need help? Talk to us.</span>
          <img src={WhatsappImg} alt="whatsapp" />
        </a>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => ({
  userLogout: () => dispatch(userLogoutAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
