import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { updateUserQuiz } from "../../../redux/actions/useraction";
import axios from "axios";
import { mainUri } from "../../../Utils/Uri";

const QuizCard = (props) => {
  const { data } = props;
  const cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  //   console.log(history);

  const myQuiz = user.myQuiz;

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " H " : " H ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " M " : " M ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " S" : " S") : "";
    return hDisplay + ": " + mDisplay;
    // + ":" + sDisplay;
  };

  // //handleByeNow
  // const handleByeNow = () => {
  //   if (user.user === null) {
  //     Swal.fire("Hello", "Please login first", "warning");
  //     history.push("/login");
  //   } else {
  //     setIsLoading(true);
  //     axios
  //       .post(
  //         `${mainUri}/quiz/quiz-payment-free/${data._id}`,
  //         {
  //           user: user.user.id,
  //         },
  //         {
  //           headers: {
  //             "auth-token": user.token,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         const result = res.data;
  //         if (result.success) {
  //           props.updateQuiz(result.data);
  //         }
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log(err);
  //       });
  //   }
  // };

  const isExistinCart = cart.some((item) => item._id === data._id);

  // ! add to cart in db
  const addToCartDb = (product) => {
    axios
      .post(
        `${mainUri}/cart/addItem/${user.user.id}`,
        { product },
        {
          headers: {
            "auth-token": user.token,
          },
        }
      )
      .then((res) => {
        const result = res.data;
        if (result.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `This ${product.type} has been added in your cart.`,
            showConfirmButton: false,
            timer: 1500,
          });
          // props.cartAdd(result.data.cartItems);
        } else if (result.success === false) {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // console.log("add result >> ", result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // !add to cart function
  const addToCartFunction = () => {
    const product = {
      _id: data._id,
      price: data.price,
      coupon: null,
      title: data.title_english,
      image: null,
      expiryDate: data.expiryDate,
      type: "quiz",
    };

    if (cart.length > 0) {
      dispatch({
        type: "ADD_TO_CART",
        payload: [...cart, product],
      });
      localStorage.setItem("prepFront", JSON.stringify([...cart, product]));
    } else {
      dispatch({
        type: "ADD_TO_CART",
        payload: [product],
      });
      localStorage.setItem("prepFront", JSON.stringify([product]));
    }
  };

  //   console.table(myQuiz);
  const isExist =
    myQuiz.length > 0 ? myQuiz.some((item) => item._id === data._id) : null;
  console.log("asasdasd", isExist);
  return (
    <div className="quiz_card_container">
      <div className="quiz_header">
        <h4 className="quiz_card_title">{data.title_english}</h4>
      </div>
      <div className="mb-2">
        <span className="quiz_hindi_title">
          <i className="fa fa-tag" /> {data.category.title}
        </span>
      </div>
      <div className="mb-2">
        Price: <strong>₹{data.price}</strong>
      </div>
      <div className="quiz_body">
        {data.title_hindi && (
          <div className="mb-2">
            <span
              className="quiz_hindi_title"
              style={{ background: "rgba(0,228,65,0.2)" }}
            >
              <i className="fa fa-hand-o-right" />
              {"  "}Available in Hindi
            </span>{" "}
            <span className="quiz_hindi_title" style={{ marginLeft: "5px" }}>
              {data.user.length} Student Enrolled
            </span>
          </div>
        )}
        <div className="price_container">
          <span className="small_text">Questions:</span>
          <span className="small_text">{data.questions.length}</span>
        </div>
        <div className="price_container" style={{ borderBottom: 0 }}>
          <span className="small_text">Total Time:</span>
          <span className="small_text">{secondsToHms(data.time)}</span>
        </div>
      </div>
      <div className="card_footer">
        {isExist ? (
          <Link to={`/quiz/${data._id}`} className="btn btn-primary btn-block">
            Start Quiz
          </Link>
        ) : (
          <>
            {isExistinCart ? (
              <Link className="btn btn-primary btn-block mt-2" to="/cart">
                Goto Cart Page <i className="fa fa-long-arrow-right"></i>
              </Link>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={addToCartFunction}
              >
                Add To Cart
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default QuizCard;
