import React, { useState, useEffect } from "react";
import axios from "axios";
import { mainUri } from "../../Utils/Uri";
import { connect } from "react-redux";
import QuizCard from "./components/QuizCard";

const QuizList = (props) => {
  const [list, setList] = useState([]);

  // get quiz list
  const getQuizList = () => {
    axios.get(`${mainUri}/quiz/frontend-quizes`).then((res) => {
      const result = res.data;
      if (result.success) {
        setList(result.data);
      }
    });
  };

  useEffect(() => {
    getQuizList();
  }, []);

  console.log(list);
  return (
    <div className="quiz_list_wrapper">
      <div className="container">
        <div className="quiz_list_header">
          <h4>Quiz List</h4>
        </div>
        <div className="quiz_list_body">
          {list.length > 0 && (
            <div className="row">
              {list.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <QuizCard data={item} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(QuizList);
