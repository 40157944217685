export const userLoginAction = (data) => {
  console.log("get user >> ", data);
  return {
    type: "USER_LOGGEDIN",
    payload: data.data,
    token: data.token,
  };
};

// logout user
export const userLogoutAction = (data) => {
  //   console.log("get user >> ", data);
  return {
    type: "USER_LOGOUT",
    payload: "LOGOUT",
  };
};

export const updateWishlistAction = (data) => {
  console.log("wishlist data >> ", data);
  return {
    type: "USER_WISHLIST_UPDATE",
    payload: data,
  };
};
// update user info
export const updateUserInfo = (data) => {
  return {
    type: "USER_INFO_UPDATE",
    payload: data,
  };
};

// update quiz
export const updateUserQuiz = (data) => {
  return {
    type: "USER_QUIZ_UPDATE",
    payload: data,
  };
};
// update Mocks
export const updateUserMock = (data) => {
  return {
    type: "USER_MOCK_UPDATE",
    payload: data,
  };
};
