import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useRef } from "react";
import { mainUri } from "../../Utils/Uri";
import axios from "axios";
import Swal from "sweetalert2";
import { userLoginAction } from "../../redux/actions/useraction";
import qs from "qs";

const UpdatePassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const location = useLocation();
  const { email, otp } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const password = useRef({});
  password.current = watch("password", "");
  const history = useHistory();

  const onSubmit = (data) => {
    // console.log("all data>>", data);
    setIsLoading(true);
    axios
      .post(`${mainUri}/users/update-password`, {
        email: email.toLowerCase(),
        otp,
        password: data.password,
      })
      .then((res) => {
        const details = res.data;
        if (details.success === true) {
          setIsLoading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: details.message,
            showConfirmButton: false,
            timer: 2500,
          });
          history.push(`/login`);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: details.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 400) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  return (
    <div className="login-container">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="login_wrapper">
              <div className="login_header">
                <h2 className="login-title">Update Password</h2>
              </div>
              <div className="login_body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="login_label">
                      Enter your new password
                    </label>
                    <input
                      id="password"
                      name="password"
                      placeholder="Enter your new password"
                      ref={register({
                        required: "Password is required.",
                        minLength: {
                          value: 6,
                          message: "Password should be at least 6 characters.",
                        },
                      })}
                      type="password"
                      className="form-control"
                    />
                    {errors.password && (
                      <span className="isError">{errors.password.message}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="login_label">Repeat password</label>
                    <input
                      name="password_repeat"
                      placeholder="Repeat password"
                      ref={register({
                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      })}
                      type="password"
                      className="form-control"
                    />
                    {errors.password_repeat && (
                      <span className="isError">
                        {errors.password_repeat.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    {isLoading ? (
                      <button
                        className="btn btn-primary btn-block"
                        style={{ opecity: 0.5 }}
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-7"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLoggedIn: (data) => dispatch(userLoginAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
