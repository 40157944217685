import React from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import CourseImg from "../../../assets/images/coursepic.png";

const CourseCardHorizontal = ({ data, link, bundle, isExpired }) => {
  // console.log("data >> ", data?.title + "-->>" +isExpired);
  let history = useHistory();

  const handleClick = () => {
    if (bundle) {
      if (isExpired) {
        return;
      } else {
        return history.push(`/bundle/${data?.slug}`);
      }
    } else {
      if (isExpired) {
        return;
      } else {
        return history.push(link);
      }
    }
  };
  return (
    <div
      className="horizontal_course_card"
      onClick={handleClick}
      style={{
        cursor: isExpired ? "not-allowed" : "pointer",
      }}
    >
      {bundle ? (
        <div className="course_img">
          {data?.image !== null ? (
            <img src={data?.image} alt={data?.title} />
          ) : (
            <img src={CourseImg} alt={data?.title} style={{ minHeight: 80 }} />
          )}
        </div>
      ) : (
        <div className="course_img">
          {data?.course.course_image !== null ? (
            <img src={data?.course.course_image} alt={data?.course.title} />
          ) : (
            <img
              src={CourseImg}
              alt={data?.course.title}
              style={{ minHeight: 80 }}
            />
          )}
        </div>
      )}

      <div className="course_details">
        {bundle ? (
          <>
            {data?.category?.title ? (
              <span className="course_category">{data?.category?.title}</span>
            ) : null}
          </>
        ) : (
          <>
            {data?.course.category?.title ? (
              <span className="course_category">
                {data?.course.category?.title}
              </span>
            ) : null}
          </>
        )}
        <h4
          className="course_link_title"
          style={{ marginTop: data?.category?.title ? 0 : 10 }}
        >
          {bundle ? (
            <p className="course_link mb-0">{data?.title}</p>
          ) : (
            <p className="course_link mb-0">{data?.course.title}</p>
          )}
        </h4>
        <small className="mb-0 text-muted ">
          <span style={{ marginRight: 10 }}>
            <i className="fa fa-calendar-check-o"></i>
          </span>
          <span>
            {isExpired ? (
              <span className="text-danger">
                <strong>Expired:</strong>
              </span>
            ) : (
              "Expires"
            )}{" "}
            {moment(bundle ? data.expiryDate : data.validity).format(
              "Do MMM, YYYY"
            )}
          </span>
        </small>
      </div>
    </div>
  );
};

export default CourseCardHorizontal;
